import moment from 'moment';

// Keep events in 2 months and Sort by startDate and endDate
export const processEvents = events => {
  return events
    .filter(({ MeetingStartDate, MeetingEndDate }) => {
      if (!MeetingStartDate || !MeetingEndDate) return false;
      const now = moment();
      const end = moment().add(2, 'months');
      return (
        (moment(MeetingStartDate).isAfter(now) || moment(MeetingEndDate).isAfter(now)) &&
        (moment(MeetingStartDate).isBefore(end) || moment(MeetingEndDate).isBefore(end))
      );
    })
    .sort((a, b) => {
      if (moment(a.MeetingStartDate).isAfter(moment(b.MeetingStartDate))) {
        return 1;
      }
      if (moment(b.MeetingStartDate).isAfter(moment(a.MeetingStartDate))) {
        return -1;
      }
      if (moment(a.MeetingEndDate).isBefore(moment(b.MeetingEndDate))) {
        return -1;
      }
      if (moment(b.MeetingEndDate).isBefore(moment(a.MeetingEndDate))) {
        return 1;
      }
      return 0;
    });
};
