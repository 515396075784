import { css } from '@emotion/core';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const style = {
  weblogImg: css`
    display: none;
  `,
};

const host = process.env.API_ROOT;
const isBrowser = typeof window !== 'undefined';

const WebLogImage = props => {
  const { personId, path, sitePageId, title, pagedata, browserPath } = props;

  const router = useRouter();
  const { query } = router;
  const source = query.source ? query.source : 'web';
  const pageTitle = title || pagedata.MetaTitle || 'APCIA';
  const url =
    browserPath && isBrowser
      ? `${host}${window.location.pathname}`
      : `${host}${path}${sitePageId ? '/' : ''}${sitePageId}`;

  const src = `/pciwebsite/weblogging/Log/Create?url=${url}&id=${sitePageId}&personid=${personId}&source=${source}&title=${pageTitle}`;

  return <img css={style.weblogImg} src={src} alt="web logging" />;
};

function mapStateToProps(state) {
  const {
    auth: {
      userinfo: { PersonID },
    },
  } = state;
  return {
    personId: PersonID,
  };
}

WebLogImage.propTypes = {
  path: PropTypes.string,
  sitePageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  pagedata: PropTypes.object,
  browserPath: PropTypes.bool,
};

WebLogImage.defaultProps = {
  path: '',
  sitePageId: '',
  title: '',
  pagedata: {},
  browserPath: false,
};

export default connect(mapStateToProps)(WebLogImage);
