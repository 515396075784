import { css, Global, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { matchAll } from '@/utils/utils';

const { sanitize } = require('dompurify');

const attachmentRegex = /(href="javascript:(?:loadwindow|window\.open)\('\/pciwebsite\/common\/page\/attachment(?:secured)?\/([0-9]*)','(?:[A-Z]*')\)")/gim;
const underlineRegex = /<span[^>]+(?:text-decoration:\s?underline).+?(?=<\/span>)<\/span>/gim;
// const tableCellRegex = /<li\s(?:.*?.*?((?:display:\s?table-cell(?:.*?))+?)<\/li>)/gmi;
const sitePageLinkRegex = /(?:http(?:s)?:\/\/www.pciaa.net)?\/?pciwebsite\/cms\/content\/viewpage(?:secured)?\?sitepageid=([0-9]+)/gim;
const newWindowLinksRegex = /<a[^>]+href="javascript:(?:loadwindow|window\.open)\('(.*?)'.*?"[^>]*>(?:.*?)<\/a>/gim;
const isBrowser = typeof window !== 'undefined';

export default class Sanitize extends PureComponent {
  sanitize = content => {
    const { wildwest, mildwest } = this.props;
    const domPurifyConfig = {
      ADD_TAGS: ['iframe'],
      FORCE_BODY: true,
      ADD_ATTR: ['target', 'href'],
      FORBID_ATTR: ['style'],
      FORBID_TAGS: ['style'],
    };
    if (wildwest || mildwest) {
      domPurifyConfig.ADD_ATTR.push('style');
      delete domPurifyConfig.FORBID_ATTR;
      delete domPurifyConfig.FORBID_TAGS;
    }
    if (wildwest) {
      domPurifyConfig.ADD_TAGS.push('style', 'h1', 'h2');
    }

    let _content = content;
    if (_content) {
      _content = content
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&');

      const attachmentMatches = matchAll(_content, attachmentRegex);

      for (let i = 0; i < attachmentMatches.length; i += 1) {
        const match = attachmentMatches[i];
        _content = _content.replace(
          match[0],
          `href="${process.env.API_ROOT}/pciwebsite/Common/page/AttachmentSecured/${
            match[2]
          }" target="_blank"`
        );
      }

      const sitepageMatches = matchAll(_content, sitePageLinkRegex);

      for (let i = 0; i < sitepageMatches.length; i += 1) {
        const match = sitepageMatches[i];
        const [full, id] = match;
        _content = _content.replace(full, `/${id}`);
      }

      if (isBrowser) {
        const newWindowLinks = matchAll(_content, newWindowLinksRegex);

        for (let i = 0; i < newWindowLinks.length; i += 1) {
          const match = newWindowLinks[i];
          const [anchor, href] = match;
          const pureAnchor = sanitize(anchor, domPurifyConfig);
          const div = document.createElement('DIV');
          div.innerHTML = pureAnchor;
          const domA = div.querySelector('a');
          domA.setAttribute('target', '_blank');
          domA.setAttribute('rel', 'noopener noreferrer nofollow');
          domA.href = href;
          _content = _content.replace(anchor, domA.outerHTML);
        }
      }

      const underlineMatches = matchAll(_content, underlineRegex);

      for (let i = 0; i < underlineMatches.length; i += 1) {
        const match = underlineMatches[i];
        const [span] = match;
        const uspan = span.replace('<span', '<u').replace('</span', '</u');
        _content = _content.replace(span, uspan);
      }

      // const tableMatches = matchAll(_content, tableRegex);

      // for (let i = 0; i < tableMatches.length; i += 1) {
      //   const match = tableMatches[i];
      //   const [ ul ] = match;
      //   const pureUL = sanitize(ul, domPurifyConfig);
      //   const div = document.createElement('DIV');
      //   div.innerHTML = pureUL;
      //   const domUL = div.querySelector('ul');
      //   if (domUL) domUL.classList.add('d-table-ul');
      //   _content = _content.replace(ul, domUL.outerHTML)
      // }
    }

    if (!isBrowser) {
      return _content;
    }

    const sanitized = sanitize(_content, domPurifyConfig);

    return sanitized;
  };

  render() {
    const { children, wildwest, mildwest, className = '', ...props } = this.props;
    return (
      <div
        className={`sanitized ${className}`}
        {...props}
        dangerouslySetInnerHTML={{
          __html: this.sanitize(children),
        }}
      />
    );
  }
}

Sanitize.propTypes = {
  wildwest: PropTypes.bool,
  mildwest: PropTypes.bool,
  children: PropTypes.string,
};

Sanitize.defaultProps = {
  wildwest: false,
  mildwest: false,
  children: '',
};
