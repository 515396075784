/* eslint-disable simple-import-sort/sort */
import { css } from '@emotion/core';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullWidthParagraphVideo from '@/src/cms/components/CMSPage/CorePage/Blocks/FullWidthParagraphVideo';
import HeroSlides from '@/src/cms/components/CMSPage/CorePage/Blocks/HeroSlides';
import DesignPage from '@/src/cms/models/DesignPage';
import { requestSavedSearches as requestComplianceSS } from '@/src/compliance/redux/complianceReducer';
import { fetchSavedSearches as requestGlobalSS } from '@/src/search/redux/actions';
import Layout from '@/src/shared/components/Layout';
import Section from '@/src/shared/components/Section';
import WebLogImage from '@/src/shared/components/WebLogImage';
import request from '@/utils/axios';
import { processEvents } from '@/utils/upcomingEvents';
import { colors } from '@/styles';

const DailyDigest = dynamic(() => import('@/src/home/components/DailyDigest'));
const HomeEvents = dynamic(() => import('@/src/home/components/HomeEvents'));
const MyAPCIA = dynamic(() => import('@/src/home/components/MyAPCIA'));
const Tour = dynamic(() => import('@/src/home/components/Tour'));
const AlertBanner = dynamic(() => import('@/src/home/components/AlertBanner'));
const CampaignBanner = dynamic(() => import('@/src/home/components/CampaignBanner'));

const style = {
  digestContainer: css`
    background-color: ${colors.accentGrayUltralight};
    @media print {
      background-color: ${colors.white};
    }
  `,
  videoContainerGray: css`
    background-color: ${colors.accentGrayUltralight};
    @media print {
      background-color: ${colors.white};
    }
  `,
  videoContainerBlue: css`
    background-color: ${colors.primaryBlue};
    @media print {
      background-color: ${colors.white};
    }
  `,
};

const Home = props => {
  const { pagedata, messageData = {} } = props;

  const dispatch = useDispatch();

  const { savedSearches, isFetchingSavedSearches } = useSelector(
    state => state.search.savedSearchesData
  );
  const personId = useSelector(state => state.auth.userinfo.PersonID);
  const { isLoggedIn } = useSelector(state => state.auth);
  const complianceSavedSearches = useSelector(
    state => state.compliance.savedSearches.savedSearches
  );

  const [digestItems, setDigestItems] = useState([]);
  const [events, setEvents] = useState([]);
  const [loadingDigest, setLoadingDigest] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [tourActive, setTourActive] = useState(false);
  const [designPageData, setPageData] = useState(pagedata);
  const [isIE11, setIsIE11] = useState(false);

  async function loadDigest() {
    try {
      const digestResponse = await request({
        url: `cms/dailydigest`,
        params: {
          pageSize: 6,
          timeout: 2000,
        },
        cache: true,
      });
      const { data } = digestResponse;
      const _results = (data.Results || []).filter(r => Boolean(r.SitePageId));
      if (!_results) {
        throw new Error('No digest items returned');
      }
      setDigestItems(_results);
      setLoadingDigest(false);
    } catch (error) {
      setDigestItems([]);
      setLoadingDigest(false);
    }
  }

  async function loadEvents() {
    setLoadingEvents(true);
    try {
      const eventsResponse = await request({
        url: `cms/upcomingevents`,
        params: {
          pageSize: 20,
        },
        cache: true,
      });
      const upcomingevents = processEvents(eventsResponse.data.Results || []);
      setEvents(upcomingevents);
      setLoadingEvents(false);
    } catch (error) {
      setEvents([]);
      setLoadingEvents(false);
    }
  }

  async function loadSavedSearches(id) {
    if (!id) return;
    try {
      dispatch(requestGlobalSS({ personId: id }));
    } catch (error) {
      console.log(error);
    }
  }

  async function loadCompliassistCharts(id) {
    if (!id) return;
    try {
      const includeOnHome = 'Y';
      dispatch(requestComplianceSS({ personId, includeOnHome }));
    } catch (error) {
      console.log(error);
    }
  }

  const fetchDesignPage = async () => {
    const pageResponse = await request({
      url: `cms/designpage`,
      params: {
        alias: 'home',
      },
    });
    if (pageResponse.data?.DateLastUpdated !== pagedata?.DateLastUpdated) {
      setPageData(pageResponse.data);
    }
  };

  useEffect(() => {
    fetchDesignPage();
    loadDigest();
    loadEvents();

    if (!!window.MSInputMethodContext && !!document.documentMode) {
      setIsIE11(true);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      loadSavedSearches(personId);
      loadCompliassistCharts(personId);
    }
  }, [personId]);

  const page = new DesignPage({ ...designPageData, ...messageData });
  const myAPCIAComponents = page.getComponentsDataByBlockId(13);
  const top6Components = myAPCIAComponents.filter(c => c.SortOrder <= 6 && c.SortOrder);
  const fullWidthParagraphVideo = page.getBlockDataByTypeId(2);
  const heroslidesBlock = page.getBlockDataByTypeId(10);
  const {
    BanerAlertActive = 'N',
    BannerAlert,
    CampaignBanner: CampaignBannerContent,
    CampaignBannerActive = 'N',
    CampaignLinkText,
    CampaignLinkUrl,
  } = designPageData;
  const { entities } = page;
  const { Components } = entities;

  const alertBannerIsActive = BanerAlertActive === 'Y';
  const campaignBannerIsActive = CampaignBannerActive === 'Y';

  return (
    <Layout>
      <Tour tourActive={tourActive} handleEndTour={() => setTourActive(false)}>
        {({ steps }) => (
          <>
            {alertBannerIsActive ? <AlertBanner name="alertBanner" text={BannerAlert} /> : null}

            {isIE11 ? (
              <AlertBanner
                name="ie11Banner"
                text="IE 11 is not a supported browser by APCIA. Please use Chrome, Firefox, Edge or Safari for a more modern web application experience. "
              />
            ) : null}

            {campaignBannerIsActive && !alertBannerIsActive ? (
              <CampaignBanner
                content={CampaignBannerContent}
                linkText={CampaignLinkText}
                linkURL={CampaignLinkUrl}
              />
            ) : null}

            {fullWidthParagraphVideo && Components[fullWidthParagraphVideo.Components[0]].Url ? (
              <Section
                css={
                  fullWidthParagraphVideo.BackgroundColor === 'blue'
                    ? style.videoContainerBlue
                    : style.videoContainerGray
                }
              >
                <FullWidthParagraphVideo
                  blockData={fullWidthParagraphVideo}
                  entities={page.entities}
                />
              </Section>
            ) : (
              <HeroSlides blockData={heroslidesBlock} entities={page.entities} homeHero />
            )}
            <Section>
              <MyAPCIA
                spotlights={top6Components}
                startTour={false}
                savedSearches={savedSearches}
                complianceSavedSearches={complianceSavedSearches}
                isFetchingSavedSearches={isFetchingSavedSearches}
                isLoggedIn={isLoggedIn}
                Step={steps.step3}
              />
            </Section>
            <Section css={style.digestContainer}>
              <DailyDigest
                loadingDigest={loadingDigest}
                digestItems={digestItems}
                isLoggedIn={isLoggedIn}
                Step={steps.step4}
              />
            </Section>
            <HomeEvents
              events={events}
              loadingEvents={loadingEvents}
              Step={steps.step5}
              NextStep={steps.step6}
            />
            <WebLogImage pagedata={designPageData} />
          </>
        )}
      </Tour>
    </Layout>
  );
};

export async function getStaticProps() {
  try {
    const pageResponse = await request({
      url: `cms/designpage`,
      params: {
        alias: 'home',
      },
    });
    return {
      props: {
        pagedata: pageResponse.data,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      props: {
        pagedata: {},
      },
    };
  }
}

export default Home;
